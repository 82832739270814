.ContentCareNavigator{
    padding: 10px 20px;
}

.comntent-CareNavigator{
    background-color: #fff;
    color: #000;
    padding: 10px;
    border-radius: 14px;
    /* height: 87vh; */
    display: block;
    width: 100%;
    /* overflow-y: scroll;
    overflow-x: hidden; */
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    margin-bottom: 4rem !important;
}

.ContentCareNavigator .main-title {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentCareNavigator .main-subtitle {
    font-size: 22px;
    color: #656565;
    font-weight: 600;
    margin-bottom: 10px;
}

.ContentCareNavigator .DefaultLeaderBoardimage {
    height: 180px;
    position: relative;
    width: 180px;
}

.ContentCareNavigator .profile-img {
    background-color: #fff;
    border: 4px solid #e8edf7;
    border-radius: 230px;
    display: block;
    height: 180px;
    object-fit: cover;
    width: 180px;
}

.ContentCareNavigator .icon-edit-btn{
    position: absolute;
    bottom: 10px;
    right: 20px;
    border-radius: 30px;
    height: 42px;
    width: 42px;
    background-color: #9F9F9F;
    border: 0;
}


.toggle-container-CareNavigator {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 28px;
    pointer-events: none;
    user-select: none;
  }
  
  .toggle-container-CareNavigator input {
    opacity: 0;
    width: 100%;
    height: 100%;
    pointer-events: all;
  }
  
  .slider-CareNavigator {
    position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #f34b50;
      transition: 0.4s;
      pointer-events: none;
      line-height: 40px;
      font-weight: 600;
  }
  
  .slider-CareNavigator::before {
      position: absolute;
      content: "";
      height: 22px;
      width: 14px;
      left: 3px;
      bottom: 3px;
      background-color: #fff;
      transition: 0.2s;
      pointer-events: none;
  }
  .slider-CareNavigator::after {
    position: absolute;
    content: "OFF";
    height: 28px;
    width: 28px;
    right: 4px;
    bottom: 6px;
    transition: 0.2s;
    color: #fff;
    pointer-events: none;
  }
  
  input:checked + .slider-CareNavigator {
    background-color: #66b071;
  }
  
  input:checked + .slider-CareNavigator:before {
    transform: translateX(32px);
  }
  
  input:checked + .slider-CareNavigator:after {
    content: "ON";
    left: 6px;
  }
  
  .slider-CareNavigator.round-CareNavigator {
    border-radius: 6px;
  }
  
  .slider-CareNavigator.round-CareNavigator:before {
    border-radius: 4px;
  }
  

.Notification-btn{
  color: #333!important;
  background: #fff !important;
  border: 1px solid #CFCFCF!important;
  font-size: 16px !important;
  padding: 8px 20px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}

.Notification-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.Modify-btn{
  color: #333!important;
  background: #fff !important;
  border: 1px solid #CFCFCF!important;
  font-size: 16px !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  width: 120px;
}

.Modify-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}


.ok-btn{
  color: #fff!important;
  background: #03335b!important;
  border: 1px solid #03335b!important;
  font-size: 16px !important;
  padding: 8px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
  width: 120px;
}

.ok-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

#updateCapacity .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: center;
  align-content: center;
}
.CapacityAssignmentTable .dBiUCQ:nth-child(1), .CapacityAssignmentTable .gdBOih:nth-child(1) {
  min-width: 180px !important;
  max-width: 180px !important;
}
.CapacityAssignmentTable .dBiUCQ:nth-child(2), .CapacityAssignmentTable .gdBOih:nth-child(2) {
  min-width: 160px !important;
  max-width: 160px !important;
}
.CapacityAssignmentTable .dBiUCQ:nth-child(3), .CapacityAssignmentTable .gdBOih:nth-child(3) {
    min-width: 140px !important;
    max-width: 140px !important;
}

.CapacityAssignmentTable .dBiUCQ:nth-child(4), .CapacityAssignmentTable .gdBOih:nth-child(4) {
  min-width: 120px !important;
    max-width: 120px !important;
    /* display: flex; */
    /* justify-content: flex-end; */
}
.CapacityAssignmentTable .dBiUCQ:nth-child(5), .CapacityAssignmentTable .gdBOih:nth-child(5) {
  min-width: 120px !important;
  max-width: 120px !important;
}
.CapacityAssignmentTable .dBiUCQ:nth-child(6), .CapacityAssignmentTable .gdBOih:nth-child(6) {
    min-width: 160px !important;
    max-width: 160px !important;
}
/* .CapacityAssignmentTable .dBiUCQ:nth-child(7), .CapacityAssignmentTable .gdBOih:nth-child(7) {
  min-width: 280px !important;
  max-width: 280px !important;
} */
.CapacityAssignmentTable .dBiUCQ:nth-child(8), .CapacityAssignmentTable .gdBOih:nth-child(8) {
  min-width: 110px !important;
  max-width: 110px !important;
}

.CareNavigatorListTable .dBiUCQ:nth-child(1), .CareNavigatorListTable .gdBOih:nth-child(1) {
  min-width: 180px !important;
  max-width: 180px !important;
}

.CareNavigatorListTable .dBiUCQ:nth-child(2), .CareNavigatorListTable .gdBOih:nth-child(2) {
  min-width: 160px !important;
  max-width: 160px !important;
}
.CareNavigatorListTable .dBiUCQ:nth-child(3), .CareNavigatorListTable .gdBOih:nth-child(3) {
  min-width: 180px !important;
  max-width: 180px !important;
}

.CareNavigatorListTable .dBiUCQ:nth-child(4), .CareNavigatorListTable .gdBOih:nth-child(4) {
  min-width: 120px !important;
  max-width: 120px !important;
}
.CareNavigatorListTable .dBiUCQ:nth-child(5), .CareNavigatorListTable .gdBOih:nth-child(5) {
  min-width: 120px !important;
  max-width: 120px !important;
}
/* 
.CareNavigatorListTable .dBiUCQ:nth-child(7), .CareNavigatorListTable .gdBOih:nth-child(7) {
  min-width: 100px !important;
  max-width: 100px !important;
} */
.CareNavigatorListTable .dBiUCQ:nth-child(8), .CareNavigatorListTable .gdBOih:nth-child(8) {
  min-width: 100px !important;
  max-width: 100px !important;
}
.CareNavigatorListTable .dBiUCQ:nth-child(8), .CareNavigatorListTable .gdBOih:nth-child(9) {
  min-width: 100px !important;
  max-width: 100px !important;
}
.CareNavigatorAppointmentsTable .gdBOih:nth-child(1), .CareNavigatorAppointmentsTable .jQZHcF:nth-child(1) {
  min-width: 160px !important;
  max-width: 160px !important;
}

.CareNavigatorAppointmentsTable .gdBOih:nth-child(2), .CareNavigatorAppointmentsTable .jQZHcF:nth-child(2) {
  min-width: 160px !important;
  max-width: 160px !important;
}

.CareNavigatorAppointmentsTable .gdBOih:nth-child(6), .CareNavigatorAppointmentsTable .jQZHcF:nth-child(6) {
  min-width: 180px !important;
  max-width: 180px !important;
}
.CareNavigatorAppointmentsTable .gdBOih:nth-child(7), .CareNavigatorAppointmentsTable .jQZHcF:nth-child(7) {
  min-width: 100px !important;
  max-width: 100px !important;
}
#showListDataTable .modal-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row !important;
  flex-shrink: 0;
}

#MemberTransferModuleBox .modal-header {
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row !important;
  flex-shrink: 0;
}
#MemberTransferModuleBox .modal-title {
  color: #404040;
  font-size: 26px;
  font-weight: 600;
}
#MemberTransferModuleBox .main-subtitle {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  text-align: center;
}